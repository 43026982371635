import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import links from "../menus/footer.json"

import Header from "./header"

const Layout = ({ children }) => {
  const { title } = useSiteMetadata()

  return (
    <>
      <Header siteTitle={title} />
      <>
        <main className="container mx-auto p-4">{children}</main>
        <footer className="p-4 bg-purple-700 text-white text-center">
          <p>
            {links
              ? links.map(link => {
                  return (
                    <Link
                      to={link.url}
                      key={link.url + link.text}
                      target={link.shouldOpenInNewTab ? "_blank" : "_self"}
                      rel="noopener noreferrer"
                      className="block md:inline-block uppercase no-underline text-white rounded hover:bg-purple-900 p-2"
                    >
                      {link.text}
                    </Link>
                  )
                })
              : null}
          </p>

          <hr />

          <p>
            &copy; {new Date().getFullYear()}, Web Development by
            {` `}
            <a
              href="https://www.bokasolutions.com"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block underline text-white hover:shadow-lg hover:bg-purple-200 hover:text-purple-900 hover:no-underline rounded"
            >
              Wayne Boka, Web Developer
            </a>
          </p>

          <p>
            <a
              href="/admin"
              className="inline-block bg-purple-900 hover:shadow-lg hover:bg-purple-200 hover:text-purple-900 rounded p-2 m-2 shadow no-underline text-white"
            >
              Enter Admin Dashboard
            </a>
          </p>
        </footer>
      </>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
